/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {Dropdown, Input, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, NavItem} from "reactstrap";
import { COLORS } from '../../utils/color';
import dummy_tick from '../../utils/dummy_tick';
import { Fa_Icon, Image_Icon, Text_Icon } from '../Unit';
import { send_analytics } from '../../utils/send_analytics';
import './style.css'


const ToolBarSectionDropdown = ({json_item, set_page_loader, handle_ui_response, update_view, tab_id}) => {
    const [is_dropdown_open, set_is_dropdown_open] = useState(false);

    const toggle = () => {
        set_is_dropdown_open(!is_dropdown_open)
    }

    const toolbar_on_click = async (id, item) => {
		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}


		var promise_resp;
		try{
            if(item){
                var analytics_data = item
                analytics_data.tab_id = tab_id
                analytics_data.category = "toolbar button clicked";
                send_analytics(analytics_data,true)
            };
            if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
            promise_resp = window.Module.toolbar_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);

		handle_ui_response(ui_response);
		
		update_view();

		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
	}

    // useEffect(() => {
    //     console.log("toolbar_dropdown", json_item)
    // }, [json_item]);

    return(
        <Dropdown style={{background:'unset', boxShadow: 'unset', position: 'unset', padding:'4px 8px',listStyle: 'none', display:'flex', alignItems:'center'}} className='infurnia_toolbar_section_dropdown' isOpen={is_dropdown_open} toggle={toggle}>
            <DropdownToggle nav style={{fontSize:'13px', padding:'0px'}}>
                <div className='drop_hover_2' style={{listStyleType:'none'}}>
                    <img style={{color:COLORS.white}} width='12px' src="/resources/toolbar_icons_new/dropdown.svg" />
                    {/* <div style={{width:0, borderRight:'2px solid ' + COLORS.gray3, height: 24}}></div> */}
                </div>
            </DropdownToggle>
            <DropdownMenu className='left16' right style={{minWidth:'0px', padding:'0px', marginTop:'21px', left:'auto'}}>
                {/* {options && options.map((o,idx) => (
                    <DropdownItem style={{listStyle: 'none', fontSize:'13px'}} className="dropdown_green_hover" key={idx} onClick={()=>{o.onClick(o.id);}}>{o.jsx?o.jsx:o.name}</DropdownItem>
                ))} */}
                {/* <DropdownItem> */}
                    <div style={{backgroundColor:COLORS.gray2, display:'flex', borderRadius:'6px'}}>
                        {
                            json_item && json_item.length && json_item.map((json_sub_item, idx) => (
                                <div className={json_sub_item.active == 'true' ?'toolbar_active hover_toolbar':'hover_toolbar'} style={{cursor:json_sub_item.disabled == 'true'?'not-allowed':'pointer',opacity:json_sub_item.disabled == 'true'?'0.3':'1', padding:'4px 2px 8px 2px'}} key={json_sub_item.id + String(idx)}>
                                    {	
                                        json_sub_item.type==="image" ?
                                        <Image_Icon
                                            onClick={!(json_sub_item.disabled == 'true')?() => {toggle(); toolbar_on_click(json_sub_item.id,json_sub_item)} : null}
                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                            unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                            unit_show_name={json_sub_item.show_name?json_sub_item.show_name:'true'}
                                            unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                            unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                            unit_disabled_message={json_sub_item.disabled_message ? json_sub_item.disabled_message : ''}
                                        />:""
                                    }
                                    {	
                                        json_sub_item.type==="text" ?	
                                        <Text_Icon
                                            onClick={!(json_sub_item.disabled == 'true')?() => {toggle(); toolbar_on_click(json_sub_item.id,json_sub_item)} : null}
                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                            unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                            unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                            unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                            unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                        />:""
                                    }
                                    {	
                                        json_sub_item.type==="icon"	?
                                        <Fa_Icon
                                            onClick={!(json_sub_item.disabled == 'true')?() => {toggle(); toolbar_on_click(json_sub_item.id,json_sub_item)} : null}
                                            unit_type={json_sub_item.type?json_sub_item.type:''}
                                            unit_id={json_sub_item.id?json_sub_item.id:''}
                                            unit_name={json_sub_item.name?json_sub_item.name:''}
                                            unit_content={json_sub_item.content?json_sub_item.content:''}
                                            unit_root={json_sub_item.route?json_sub_item.route:''}
                                            unit_show={json_sub_item.show?json_sub_item.show:'true'}
                                            unit_disabled={(json_sub_item.disabled == 'true')?json_sub_item.disabled:''}
                                            unit_active={json_sub_item.active == 'true'?json_sub_item.active:''}
                                            unit_disabled_message={json_item.disabled_message ? json_item.disabled_message : ''}
                                        />:""
                                    }
                                    {	
                                        json_sub_item.type==="separator"? " " : " "
                                        
                                    }
                                </div>
                            ))
                        }
                    </div>
                {/* </DropdownItem> */}
            </DropdownMenu>
        </Dropdown>
    )
}

export default ToolBarSectionDropdown