import React from 'react';
import { Material_picker_new, Add_from_store, Vertical_options, DeleteSvg, EditSvg, BuildingSvg, Isometric, Lineweight, Textures, Tiling_layout, Reflected_ceiling_plan, Skeleton_mode, View_Layers, Toolguide, Lock_view, Unlock_view, NewTabSvg, BookSvg, GlobeSvg, ChatSvg, YouTubeSvg, View_settings, Close_view, WorldSvg, FloorSvg, RoomSvg, CrossSvg, FilterSvg, MinimizeSvg, ChevronLeftSvg, InfoSvg, MaximizeSvg, MaterialSphereSvg, MaterialCubeSvg, ImagePlaceholderSvg, ExpandSvg, DesignUserSvg, OrganizationSvg } from '../../resources/Resources';

const icons = {
    'edit': EditSvg,
    'delete': DeleteSvg,
    'isometric' : Isometric, 
    'textures' : Textures,
    'lineweight' : Lineweight,
    'tiling_layout' : Tiling_layout, 
    'reflected_ceiling_plan' : Reflected_ceiling_plan,
    'skeleton_mode' : Skeleton_mode,
    'tool_guide' : Toolguide,
    'material_picker_new': Material_picker_new,
    'add_from_store': Add_from_store,
    'vertical_options': Vertical_options,
    'lock' : Lock_view,
    'unlock': Unlock_view,
    'View_settings': View_settings,
    'view_layers': View_Layers,
    'close_view': Close_view,
    'new_tab': NewTabSvg,
    'book': BookSvg,
    'globe': GlobeSvg,
    'chat': ChatSvg,
    'youtube': YouTubeSvg,
    'building': BuildingSvg,
    'world': WorldSvg,
    'floor': FloorSvg,
    'room': RoomSvg,
    'cross': CrossSvg,
    'filter': FilterSvg,
    'minimize': MinimizeSvg,
    'chevron_left': ChevronLeftSvg,
    'info': InfoSvg,
    'maximize': MaximizeSvg,
    'material_sphere': MaterialSphereSvg,
    'material_cube': MaterialCubeSvg,
    'image_placeholder': ImagePlaceholderSvg,
    'design_user': DesignUserSvg,
    'organisation': OrganizationSvg,
    'expand': ExpandSvg
}

const Icons = ({ name, style, ...props }) => {
    const Icon = icons[name]; // Assuming all SVG icons are in the /icons folder

    if (Icon) {
        return (
            <div style={{ display: 'flex', ...style }} {...props}>
                <Icon />
            </div>)
    }

    return (
        ''
    );
};

export default Icons;
